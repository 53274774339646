<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">ATA Individual Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-4">
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Module Name</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="module"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id !== module.id"
                    :loading="!modules.length"
                    @option:selected="
                      FilterData(module, employee, training_date, trainer)
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Assessor Name</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="employee"
                    :options="employees"
                    :clearable="false"
                    :selectable="(options) => options.id != employee.id"
                    :loading="!employees.length"
                    @option:selected="
                      FilterData(module, employee, training_date, trainer)
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Training Date</label>
                <div style="width: 200px">
                  <flat-pickr
                    class="form-control"
                    placeholder="Select Training Date"
                    name="date"
                    v-model="training_date"
                    @change="
                      FilterData(module, employee, training_date, trainer)
                    "
                  >
                  </flat-pickr>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Trainees Name</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="trainer"
                    :options="employees"
                    :clearable="false"
                    :selectable="(options) => options.id != trainer.id"
                    :loading="!employees.length"
                    @option:selected="
                      FilterData(module, employee, training_date, trainer)
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table mb-3 table-striped dt-responsive"
                id="datatable"
                v-if="!loading"
                style="width: 2500px"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Module Name</th>
                    <th>Assessor Name</th>
                    <th>Training Date</th>
                    <th>Trainees Name</th>
                    <th v-for="(item, index) in assessment_list" :key="index">
                      {{ item.question }}
                    </th>
                    <th style="width: 150px">Avarage Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data_result" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.module_name }}</td>
                    <td>{{ item.assessor_name }}</td>
                    <td>{{ item.training_date }}</td>
                    <!-- <td>{{ item.trainer_name }}</td> -->
                    <td>{{ item.trainee_name }}</td>
                    <td v-for="(assessment, ind) in item.answers" :key="ind">
                      {{ assessment }}
                    </td>
                    <td>{{ item.average_score + "%" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Timeline
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $, { data } from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../../components/shared/badgeDanger.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  components: {
    badgeSuccess,
    badgeDanger,
    vSelect,
    flatPickr,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      assessment_answer: [],
      assessment_result: [],
      data_result: [],
      assessment_list: [],
      unique_ids: [],
      // tableWidth: 0,
      training_date: "",
      employee: "",
      trainer: "",
      module: "",
      modules: [],
      employees: [],
      pdfLoading: false,
      excelLoading: false,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAssessmentResult() {
      //   this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/after-assessment-result`)
        .then((response) => {
          this.assessment_result = response.data.data;
          this.assessment_result.forEach((result) => {
            if (
              result.assessment_result_answer &&
              result.assessment_result_answer.length > 0
            ) {
              result.assessment_result_answer.forEach((result_answer) => {
                if (this.unique_ids.includes(result_answer?.question_id))
                  return;
                this.assessment_list.push({
                  id: result_answer?.question_id,
                  question: result_answer?.question_name?.name,
                });
                this.unique_ids.push(result_answer?.question_id);
              });

              // this.tableWidth =
              //   this.tableWidth + this.assessment_list.length * 300;
            }
          });
          this.getResultData();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#datatable").DataTable();
    },
    getResultData() {
      this.assessment_result.forEach((result) => {
        if (
          result.assessment_result_answer &&
          result.assessment_result_answer.length > 0
        ) {
          let answer_loop = [];
          let matchFound = false;
          let total_score = 0;
          let questionCount = 0;

          result.assessment_result_answer.forEach((result_answer) => {
            if (this.unique_ids.includes(result_answer?.question_id)) {
              let score;

              if (result_answer?.question_name?.question_type == "radio") {
                switch (result_answer?.answer) {
                  case "Strongly Agree":
                    score = 100;
                    break;
                  case "Agree":
                    score = 80;
                    break;
                  case "Disagree":
                    score = 60;
                    break;
                  case "Strongly Disagree":
                    score = 40;
                    break;
                  default:
                    score = 0;
                    break;
                }
                questionCount++;
                total_score += score;
                score = score + "%";
              } else {
                score = result_answer?.answer;
              }

              answer_loop.push({
                id: result_answer?.question_id,
                name: result_answer.answer,
                score: score,
              });
              matchFound = true;
              return;
            }
          });

          let answers = [];
          this.assessment_list.forEach((assessment) => {
            let found = false;
            answer_loop.forEach((answer) => {
              if (answer.id == assessment.id) {
                answers.push(answer.score);
                found = true;
                return;
              }
            });
            if (!found) answers.push("0%");
          });

          this.data_result.push({
            module_name:
              result?.assessment_before_one_weeks?.monthly_plan?.module?.name,
            assessor_name: result?.assessment_before_one_weeks?.assessor?.name,
            training_date: result?.assessment_before_one_weeks?.training_date,
            trainer_name:
              result?.assessment_before_one_weeks?.monthly_plan?.trainers
                ?.map((trainer) => trainer?.user?.name)
                .join(", "),
            trainee_name:
              result?.assessment_before_one_weeks?.enrolled_user?.name,
            answers: answers,
            average_score: total_score / questionCount,
          });
        }
      });
    },
    exportPdfClick() {
      const dataArray = $("#datatable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];
      const title = "ATA Individual Report";
      let headers = [
        "No",
        "Module Name",
        "Assessor Name",
        "Training Date",
        "Trainees Name",
      ];
      this.assessment_list.forEach((assessment) => {
        headers.push(assessment.question);
      });
      headers.push("Average Score");

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });
      const pageWidth = this.assessment_list.length * 150;
      exportPdf(headers, sheetData, title, pageWidth);

      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#datatable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      const title = "ATA Individual Report";
      let headers = [
        "No.",
        "Module Name",
        "Assessor Name",
        "Training Date",
        "Trainees Name",
      ];
      this.assessment_list.forEach((assessment) => {
        headers.push(assessment.question);
      });
      headers.push("average_score");

      sheetData.push(headers);

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });
      exportExcel(sheetData, title);
      this.excelLoading = false;
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#datatable").DataTable().draw();
    },
    async getAllModules() {
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.$Progress.finish();
      });
    },
    FilterData(module, employee, training_date, trainer) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        const check_module = module?.name == data[1] ? true : false;
        const check_employee = employee?.name == data[2] ? true : false;
        const check_training_date = training_date == data[3] ? true : false;
        const check_trainer = trainer?.name == data[4] ? true : false;

        return (check_trainer || !trainer) &&
          (check_employee || !employee) &&
          (check_training_date || !training_date) &&
          (check_module || !module)
          ? true
          : false;
      });
      $("#datatable").DataTable().draw();
    },
    getAllEmployees() {
      this.employees = this.$store.getters["odoo/getAllEmployees"];
      if (!this.employees.length) {
        setTimeout(() => {
          this.getAllEmployees();
        }, 1500);
      }
    },
    refreshData() {
      this.module = "";
      this.employee = "";
      this.trainer = "";
      this.training_date = "";
      this.clearFilter();
    },
  },

  created() {
    this.clearFilter();
    this.getAssessmentResult();
    this.getAllModules();
    this.getAllEmployees();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
